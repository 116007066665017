import React, { useState } from 'react';

import classNames from 'classnames';

import TopLevelMenu from './TopLevelMenu';

const AssetMenu = ({
  assetMenuData,
  currentItemsMap,
  onUpdateItem,
  isHidden,
  onResetLastActivity,
}) => {
  const [openMenuName, setOpenMenuName] = useState(null);

  return (
    <div
      className={classNames(
        'flex flex-col sm:flex-row justify-end items-center sm:items-start pt-16 sm:pt-4 sm:pl-8'
      )}
    >
      <nav
        className={classNames('flex flex-col sm:mr-4 transition-opacity duration-700', {
          'opacity-0': isHidden,
        })}
      >
        <div>
          {assetMenuData.map(({ name, items }) => (
            <TopLevelMenu
              name={name}
              key={name}
              menuData={items}
              isOpen={openMenuName === name}
              currentItemName={currentItemsMap[name]}
              onSelect={newItemName => onUpdateItem(name, newItemName)}
              onToggleOpen={() => setOpenMenuName(openMenuName === name ? null : name)}
              onResetLastActivity={onResetLastActivity}
            />
          ))}
        </div>
      </nav>

      <div
        className={classNames(
          'font-bold sm:hidden fixed w-screen bottom-0 flex items-center justify-center',
          {
            hidden: isHidden,
          }
        )}
        style={{ backgroundColor: '#ffffffd1', height: 40 }}
      >
        On mobile? Try going landscape.
      </div>
    </div>
  );
};
export default AssetMenu;
