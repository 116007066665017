const assetMenuData = [
  {
    name: 'Landscape',
    items: [
      { name: 'Beach at Dawn' },
      { name: 'Candlelight' },
      { name: 'Cozy Fireplace' },
      { name: 'Early Morning Dew' },
      { name: 'Evergreen Snow' },
      { name: 'Foggy Coast' },
      { name: 'Highlands Breeze' },
      { name: 'Misty Countryside' },
      { name: 'Mountain River' },
      { name: 'Rainy Day' },
      { name: 'Rocks at Sea' },
      { name: 'Wildflower Fog' },
      { name: 'Windy Pasture' },
      { name: 'Winter Lake' },
    ],
  },
  {
    name: 'Music',
    items: [
      { name: 'Bed Sheets' },
      { name: 'Dreams' },
      { name: 'Dreamweaver' },
      { name: 'Floating in Space' },
      { name: 'Math in Motion' },
      { name: 'Mountainscape' },
      { name: 'Multitudes' },
      { name: 'Now or Never' },
      { name: 'Silhouette' },
      { name: 'Stumbling' },
      { name: 'Wandering' },
    ],
  },
  {
    name: 'Ambience',
    items: [
      { name: 'Calm Ocean' },
      { name: 'Crashing Waves' },
      { name: 'Fire Wood Crackle' },
      { name: 'Forest' },
      { name: 'Howling Polar Wind' },
      { name: 'Morning River' },
      { name: 'Night River' },
      { name: 'Night Woods' },
      { name: 'Rain Fall' },
      { name: 'River Flow' },
      { name: 'Thunderstorm' },
      { name: 'Wind Blowing' },
    ],
  },
];
export default assetMenuData;
