import React from 'react';

import classNames from 'classnames';

import fullscreenIconExpand from './fullscreen-icon-expand.svg';
import fullscreenIconContract from './fullscreen-icon-contract.svg';

const FullscreenButton = ({ isHidden, onEnterFullscreen, isFullscreen }) => {
  return (
    <button className="fixed" style={{ bottom: '1rem', right: '1rem' }} onClick={onEnterFullscreen}>
      <img
        src={isFullscreen ? fullscreenIconContract : fullscreenIconExpand}
        width="90"
        alt="Fullscreen icon"
        className={classNames('transition-opacity duration-700 cursor-pointer', {
          'opacity-0': isHidden,
          'opacity-75 hover:opacity-100': !isHidden,
        })}
      />
    </button>
  );
};
export default FullscreenButton;
