import React, { Fragment } from 'react';

import xIcon from './x-icon.png';
import ellieAndDaniel from './ellie-and-daniel.png';

const EnterPage = ({ onEnter, pageState, setPageState }) => {
  const handleAboutClick = event => {
    setPageState('about');
  };

  const handleContactClick = event => {
    setPageState('contact');
  };

  return (
    <div className="flex flex-col items-center h-screen">
      <div className="flex justify-end mt-4 px-8 w-screen">
        <nav className="flex text-white font-bold z-10" style={{ fontSize: 22 }}>
          <button onClick={handleAboutClick}>about</button>
          <button onClick={handleContactClick} className="ml-4">
            contact
          </button>
        </nav>
      </div>

      <div className="flex-grow flex justify-center items-center">
        {pageState === 'enter' ? (
          <button
            className="text-white font-bold"
            onClick={onEnter}
            style={{
              borderRadius: 5,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              fontSize: 30,
              width: 216,
              height: 80,
              marginBottom: 18,
            }}
          >
            Enter
          </button>
        ) : (
          <div
            className="text-white mx-4 md:mx-0 mt-4 sm:mt-0 absolute sm:relative left-0 sm:left-auto"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              borderRadius: 5,
              fontSize: 18,
              maxWidth: 800,
              top: 40,
            }}
          >
            <div style={{ paddingTop: 10, paddingBottom: 78 }}>
              <div className="flex justify-end" style={{ paddingRight: 10 }}>
                <img
                  src={xIcon}
                  width="29"
                  height="29"
                  style={{ width: 29, height: 29 }}
                  className="cursor-pointer"
                  onClick={() => setPageState('enter')}
                  alt="x icon"
                />
              </div>
              <div className="flex flex-col sm:flex-row items-center mt-8 px-4 sm:px-16">
                {pageState === 'about' ? (
                  <Fragment>
                    <img
                      src={ellieAndDaniel}
                      className="mr-0 sm:mr-8 mb-4 sm:mb-0"
                      style={{ width: 204, height: 357 }}
                      width="204"
                      height="357"
                      alt="Ellie and Daniel in a field of flowers"
                    />

                    <div>
                      <h3 className="uppercase text-bold mb-8" style={{ fontSize: 40 }}>
                        Hello
                      </h3>
                      <p style={{ fontFamily: 'Tajawal, sans-serif' }}>
                        Hi there and welcome to Dreamie. Our names are Ellie and Daniel and we’re so
                        happy that you stumbled upon our sanctuary in this tiny corner of the
                        internet. In this day and age, it can be hard to escape from seemingly
                        insurmountable levels of stress, anxiety, and emotional overload. We created
                        Dreamie with the hopes that our guided imagery and soundscapes will help
                        focus your mind on the present and provide a mental escape from the demands
                        of everyday life. If you have suggestions, questions, or any other
                        experience that you would like to share, be sure to drop us a line at
                        dreamiehelp@gmail.com!
                      </p>
                    </div>
                  </Fragment>
                ) : (
                  <div>
                    <h2 className="uppercase" style={{ fontSize: 40 }}>
                      Contact
                    </h2>

                    <p
                      className="mt-8 mr-0 sm:mr-32"
                      style={{ maxWidth: 444, fontFamily: 'Tajawal, sans-serif' }}
                    >
                      Have questions, suggestions, or just want to chat? Drop us a line at
                      dreamiehelp@gmail.com and we’ll do our best to respond within 24 hours!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EnterPage;
