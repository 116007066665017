import React, { useState, useEffect, useRef } from 'react';
import EnterPage from './EnterPage';
import AssetMenu from '../AssetMenu';
import FullscreenButton from '../FullscreenButton';

import assetMenuData from './asset_menu_data';

import classNames from 'classnames';

import './main.css';

const Entry = () => {
  const musicAudioRef = useRef(null);
  const ambienceAudioRef = useRef(null);

  const videoRef = useRef(null);
  const videoSourceRef = useRef(null);

  const lastUserActivityAt = useRef(new Date().getTime());

  const [pageState, setPageState] = useState('enter');

  const [isMenuHidden, setIsMenuHidden] = useState(true);

  const [currentLandscape, setCurrentLandscape] = useState('Early Morning Dew');
  const [currentMusic, setCurrentMusic] = useState(null);
  const [currentAmbience, setCurrentAmbience] = useState(null);

  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  useEffect(() => {
    document.onfullscreenchange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
  });

  const handleEnter = () => {
    setPageState('entered');

    setIsMenuHidden(false);
  };

  const handleUpdateItem = (topLevelName, newItemName) => {
    switch (topLevelName) {
      case 'Landscape':
        setCurrentLandscape(newItemName);
        break;
      case 'Music':
        if (currentMusic === newItemName) {
          musicAudioRef.current.pause();
          setCurrentMusic(null);
        } else {
          musicAudioRef.current.src = `./assets/music/${newItemName}.mp3`;
          musicAudioRef.current.play();
          setCurrentMusic(newItemName);
        }
        break;
      case 'Ambience':
        if (currentAmbience === newItemName) {
          ambienceAudioRef.current.pause();
          setCurrentAmbience(null);
        } else {
          ambienceAudioRef.current.src = `./assets/ambience/${newItemName}.mp3`;
          ambienceAudioRef.current.play();
          setCurrentAmbience(newItemName);
        }
        break;
      default:
        break;
    }
  };

  const currentItemsMap = {
    Landscape: currentLandscape,
    Music: currentMusic,
    Ambience: currentAmbience,
  };

  useEffect(() => {
    if (!videoSourceRef || !videoRef) {
      return;
    }

    videoSourceRef.current.src = `./assets/videos/${currentLandscape}.mp4`;
    videoRef.current.load();
  }, [currentLandscape]);

  useEffect(() => {
    setInterval(() => {
      if (new Date().getTime() - lastUserActivityAt.current >= 4000) {
        setIsMenuHidden(true);
      }
    }, 500);
  }, []);

  const resetLastActivity = () => {
    lastUserActivityAt.current = new Date().getTime();
    setIsMenuHidden(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetLastActivity, { capture: true, passive: true });
    document.addEventListener('click', resetLastActivity, { capture: true, passive: true });
    document.addEventListener('keydown', resetLastActivity, { capture: true, passive: true });
    document.addEventListener('scroll', () => resetLastActivity, { capture: true, passive: true });
  }, []);

  const handleEnterFullscreen = () => {
    if (!!document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.getElementById('root').requestFullscreen();
    }
  };

  return (
    <div className="relative">
      <div
        className={classNames('absolute top-0 left-0 flex pt-4 px-8 w-screen', {
          'justify-start': pageState !== 'entered',
          'justify-center sm:justify-start': pageState === 'entered',
        })}
      >
        <button onClick={() => setPageState('enter')}>
          <h1
            className={classNames(
              'uppercase text-white font-bold transition-opacity duration-700',
              { 'opacity-0': pageState === 'entered' && isMenuHidden }
            )}
            style={{ fontSize: 24 }}
          >
            Dreamie
          </h1>
        </button>
      </div>

      {pageState !== 'entered' && (
        <EnterPage onEnter={handleEnter} pageState={pageState} setPageState={setPageState} />
      )}

      <AssetMenu
        assetMenuData={assetMenuData}
        currentItemsMap={currentItemsMap}
        onUpdateItem={handleUpdateItem}
        isHidden={pageState !== 'entered' || isMenuHidden}
        onResetLastActivity={resetLastActivity}
      />

      <FullscreenButton
        onEnterFullscreen={handleEnterFullscreen}
        isHidden={pageState !== 'entered' || isMenuHidden}
        isFullscreen={isFullscreen}
      />

      <video
        playsInline
        autoPlay
        muted
        loop
        className="fixed w-screen h-screen top-0 left-0 object-cover"
        style={{ zIndex: -1 }}
        ref={videoRef}
      >
        <source
          src={`./assets/videos/${currentLandscape}.webm`}
          type="video/webm"
          ref={videoSourceRef}
        />
        <source
          src={`./assets/videos/${currentLandscape}.mp4`}
          type="video/mp4"
          ref={videoSourceRef}
        />
      </video>

      <audio loop controls ref={musicAudioRef} className="hidden" />
      <audio loop controls ref={ambienceAudioRef} className="hidden" />
    </div>
  );
};
export default Entry;
