import React, { Fragment, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';

import downChevronImage from './down-chevron.png';
import checkmarkImage from './checkmark.png';

const TopLevelMenu = ({
  name,
  menuData,
  isOpen,
  onToggleOpen,
  currentItemName,
  onSelect,
  onResetLastActivity,
}) => {
  const scrollableContainerRef = useRef(null);
  const scrollbarThumbRef = useRef(null);

  useLayoutEffect(() => {
    if (!scrollableContainerRef.current || !scrollbarThumbRef.current) {
      return;
    }

    setTimeout(() => {
      const thumbHeight =
        (scrollableContainerRef.current.clientHeight /
          scrollableContainerRef.current.scrollHeight) *
        scrollableContainerRef.current.clientHeight;
      scrollbarThumbRef.current.style.height = thumbHeight + 'px';
    }, 300);
  }, [isOpen]);

  useLayoutEffect(() => {
    if (!scrollableContainerRef.current || !scrollbarThumbRef.current) {
      return;
    }

    scrollableContainerRef.current.addEventListener(
      'scroll',
      () => {
        scrollbarThumbRef.current.style.marginTop =
          (scrollableContainerRef.current.scrollTop / scrollableContainerRef.current.scrollHeight) *
            scrollableContainerRef.current.clientHeight +
          'px';

        onResetLastActivity();
      },
      { passive: true }
    );
  }, [onResetLastActivity]);

  return (
    <Fragment>
      <div
        className="nav-button cursor-pointer uppercase text-white mb-1 flex justify-between items-center"
        onClick={onToggleOpen}
      >
        <span className="nav-title">{name}</span>
        <img
          src={downChevronImage}
          alt=""
          width="24"
          height="24"
          className={classNames('chevron-image', {
            'chevron-image--flipped': isOpen,
          })}
        />
      </div>

      <div
        className={classNames('sub-nav-scrollbar-container flex mb-2 mt-2', {
          'sub-nav-scrollbar-container--collapsed': !isOpen,
        })}
      >
        <div
          className="sub-nav-container flex flex-col items-end flex-grow"
          ref={scrollableContainerRef}
        >
          {menuData.map(menuItem => (
            <div
              className="sub-nav-button sub-nav-button--checked cursor-pointer text-white mb-2 flex"
              key={menuItem.name}
              onClick={() => onSelect(menuItem.name)}
              style={{ minHeight: 44 }}
            >
              {currentItemName === menuItem.name && (
                <img
                  src={checkmarkImage}
                  alt=""
                  width="24"
                  height="24"
                  className="checkmark-image"
                />
              )}
              <span className="sub-nav-title">{menuItem.name}</span>
            </div>
          ))}
        </div>

        <div className="scrollbar-container">
          <div className="scrollbar-thumb" ref={scrollbarThumbRef}></div>
        </div>
      </div>
    </Fragment>
  );
};
export default TopLevelMenu;
